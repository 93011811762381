import React from 'react';
import '../styles/Footer.css';
import { FaGithub, FaLinkedin, FaXTwitter } from 'react-icons/fa6';

const socialLinks = [
  { href: 'https://github.com/stevenmarquez-git', label: 'GitHub', Icon: FaGithub },
  { href: 'https://x.com/stevenmarquez_x', label: 'X', Icon: FaXTwitter },
  { href: 'https://linkedin.com/in/steven-marquez-jr-b2155a238', label: 'LinkedIn', Icon: FaLinkedin }
];

const Footer = ({ socialPosition = 'right', messagePosition = 'left' }) => {
  const currentYear = new Date().getFullYear();

  const SocialIcons = () => (
    <div className="social-icons">
      {socialLinks.map(({ href, label, Icon }) => (
        <a
          key={label}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={label}
        >
          <Icon />
        </a>
      ))}
    </div>
  );

  const FooterMessage = () => (
    <div className="footer-message-container">
      <p className="footer-message">&copy; {currentYear} Steven Marquez Jr.</p>
      <p className="footer-submessage">Crafted with House mixes on repeat.</p>
    </div>
  );

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className={`footer-${socialPosition}`}>
          <SocialIcons />
        </div>
        <div className={`footer-${messagePosition}`}>
          <FooterMessage />
        </div>
      </div>
    </footer>
  );
};

export default Footer;