import React from 'react';
import { FaGithub, FaExternalLinkAlt, FaReact, FaNodeJs, FaVuejs, FaAngular } from 'react-icons/fa';
import { SiMongodb, SiExpress, SiPostgresql, SiDjango, SiMysql } from 'react-icons/si';
import '../styles/Expertise.css';
import image1 from '../assets/image2.jpg';
// import image2 from '../assets/image2.jpg';
// import image3 from '../assets/image3.jpg';

const TechIcon = ({ tech }) => {
  const icons = {
    React: FaReact,
    'Node.js': FaNodeJs,
    MongoDB: SiMongodb,
    'Vue.js': FaVuejs,
    Express: SiExpress,
    PostgreSQL: SiPostgresql,
    Angular: FaAngular,
    Django: SiDjango,
    MySQL: SiMysql
  };
  const Icon = icons[tech];
  return Icon ? (
    <div className="tech-icon-wrapper" title={tech}>
      <Icon aria-label={tech} />
    </div>
  ) : null;
};

const ProjectCard = ({ image, title, description, techStack, githubLink, liveLink }) => {
  const disableContextMenu = React.useCallback((e) => e.preventDefault(), []);

  return (
    <div className="project-card">
      <div className="project-image-container">
        <img 
          src={image} 
          alt={title} 
          className="project-image" 
          onContextMenu={disableContextMenu}
          draggable="false"
        />
        <div className="image-overlay" />
      </div>
      <div className="project-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="tech-stack">
          {techStack.map((tech, index) => (
            <TechIcon key={index} tech={tech} />
          ))}
        </div>
        <div className="project-links">
          <a 
            href={githubLink}
            target="_blank" 
            rel="noopener noreferrer" 
            className="view-button"
            aria-label={`View GitHub: ${title}`}
          >
            <FaGithub /> GitHub
          </a>
          <a 
            href={liveLink}
            target="_blank" 
            rel="noopener noreferrer" 
            className="view-button"
            aria-label={`View Live Demo: ${title}`}
          >
            <FaExternalLinkAlt /> Live Demo
          </a>
        </div>
      </div>
    </div>
  );
};

const Expertise = () => {
  const projects = [
    {
      image: image1,
      title: 'Supply Chain Tracker',
      description: "A smart contract to track products from manufacturer to consumer. Each product's journey is logged on the blockchain, providing authenticity and transparency.",
      techStack: ['React', 'Node.js', 'MongoDB'],
      githubLink: 'https://github.com/stevenmarquez-git',
      liveLink: 'https://smart-contract-marketplace-demo.com'
    },
    // {
    //   image: image2,
    //   title: 'DeFi Lending Platform',
    //   description: 'A decentralized finance platform enabling peer-to-peer lending and borrowing of cryptocurrencies.',
    //   techStack: ['Vue.js', 'Express', 'PostgreSQL'],
    //   githubLink: 'https://github.com/stevenmarquez-git',
    //   liveLink: 'https://defi-lending-platform-demo.com'
    // },
    // {
    //   image: image3,
    //   title: 'NFT Minting dApp',
    //   description: 'A decentralized application for creating, minting, and trading unique NFTs on the blockchain.',
    //   techStack: ['Angular', 'Django', 'MySQL'],
    //   githubLink: 'https://github.com/stevenmarquez-git',
    //   liveLink: 'https://nft-minting-dapp-demo.com'
    // }
  ];

  return (
    <section className="expertise-section" id="expertise">
      <h2>Featured Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
};

export default Expertise;