import React, { useCallback, useMemo } from 'react';
import '../styles/Header.css';
import profileImage from '../assets/IMG_9685.jpg';

const HeaderInfo = () => {
  const disableContextMenu = useCallback((e) => e.preventDefault(), []);

  const profileImageStyle = useMemo(() => ({
    backgroundImage: `url(${profileImage})`,
  }), []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="header-info">
      <div
        className="profile-image"
        style={profileImageStyle}
        onContextMenu={disableContextMenu}
        onClick={scrollToTop}
        role="button"
        tabIndex={0}
      >
        <div className="image-overlay" />
      </div>
      <div className="name-title">
        <h1 className="name">Steven Marquez Jr.</h1>
        <h2 className="title">Blockchain Developer</h2>
      </div>
    </div>
  );
};

export default React.memo(HeaderInfo);