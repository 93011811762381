import React, { useState, useEffect } from 'react';
import { FaEthereum } from 'react-icons/fa6';
import { SiSolana, SiCardano, SiPolkadot, SiBinance, SiBlockchaindotcom } from 'react-icons/si';
import axios from 'axios';
import '../styles/CryptoGrid.css';

// Crypto data array with static information
const cryptoData = [
  { name: "Ethereum (ETH)", symbol: FaEthereum, id: "ethereum", description: "The OG smart contract platform; where dApp dreams come true." },
  { name: "Solana (SOL)", symbol: SiSolana, id: "solana", description: "The speedster blockchain; fast, cheap, and always on the move." },
  { name: "Cardano (ADA)", symbol: SiCardano, id: "cardano", description: "The academic blockchain; slow and steady wins the race with peer-reviewed tech." },
  { name: "Polkadot (DOT)", symbol: SiPolkadot, id: "polkadot", description: "The blockchain diplomat; connecting all chains for a united crypto world." },
  { name: "Binance Smart Chain (BSC)", symbol: SiBinance, id: "binancecoin", description: "Ethereum's cost-effective cousin; same tools, less fuel." },
  { name: "Tezos (XTZ)", symbol: SiBlockchaindotcom, id: "tezos", description: "The self-evolving blockchain; smart contracts with an upgrade button." }
];

// CryptoCard component for displaying individual crypto information
const CryptoCard = ({ name, symbol: Icon, id, description, price, currency }) => (
  <div className="card">
    <Icon className="img" />
    <div className="textBox">
      <p className="text head">{name}</p>
      <p className="text description">{description}</p>
      <span>Live Price</span>
      <p className="text price">
        {price ? `${price.toLocaleString(undefined, { style: 'currency', currency: currency })}` : 'Loading...'}
      </p>
    </div>
  </div>
);

const CryptoGrid = () => {
  const [prices, setPrices] = useState({});
  const [currency, setCurrency] = useState('EUR');

  useEffect(() => {
    const detectCurrency = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const detectedCurrency = response.data.currency;
        setCurrency(detectedCurrency);
      } catch (error) {
        console.error('Error detecting currency:', error);
        setCurrency('EUR'); // Default to EUR if detection fails
      }
    };

    detectCurrency();
  }, []);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const ids = cryptoData.map(crypto => crypto.id).join(',');
        const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=${currency.toLowerCase()}`);
        setPrices(response.data);
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
      }
    };

    fetchPrices();
    const priceTimer = setInterval(fetchPrices, 60000); // Update prices every minute

    return () => clearInterval(priceTimer);
  }, [currency]);

  return (
    <div className="crypto-grid">
      {cryptoData.map((crypto, index) => (
        <CryptoCard 
          key={index} 
          {...crypto} 
          price={prices[crypto.id]?.[currency.toLowerCase()]}
          currency={currency}
        />
      ))}
    </div>
  );
};

export default CryptoGrid;
