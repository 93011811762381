import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Profile.css';
import profileImage from '../assets/about-picture.jpg'; // Import your profile image

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h2>Something went wrong. Please try again later.</h2>;
        }

        return this.props.children;
    }
}

const Profile = React.memo(() => {
    const disableContextMenu = React.useCallback((e) => e.preventDefault(), []);
    
    const degrees = [
        { name: 'Bachelor of Applied Technology in Cybersecurity', institution: 'Collin College', year: '2026' },
        { name: 'Associate of Infomation Systems Cybersecurity', institution: 'Collin College', year: '2024' },
        { name: 'Associate of General Studies', institution: 'Collin College', year: '2022' }
    ];

    return (
        <ErrorBoundary>
            <section className="about-me" id="profile">
                <div className="about-content">
                    <motion.h2
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        aria-label="About Me"
                    >
                        Who I Am & My Path
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        aria-label="Personal description"
                    >
                        I am a blockchain developer driven by a fascination with technology, science, and the fast-paced evolution of the digital world. With a focus on smart contracts and a commitment to secure, efficient blockchain solutions, I bring both expertise and a forward-thinking approach to my work. Currently, I am pursuing a Bachelor's degree in Cybersecurity, inspired by the potential of IoT and smart cities. I am dedicated to pushing the boundaries of what's possible in blockchain technology.
                    </motion.p>
                    <ErrorBoundary>
                        <motion.div
                            className="education-container"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.4, duration: 0.5 }}
                            aria-labelledby="education-heading"
                        >
                            <h3 id="education-heading">Education</h3>
                            <ul className="education-list">
                                {degrees.map((degree, index) => (
                                    <motion.li
                                        key={degree.name}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 + index * 0.1, duration: 0.3 }}
                                        role="listitem"
                                        aria-label={`Degree: ${degree.name}`}
                                    >
                                        <h4>{degree.name}</h4>
                                        <p>{degree.institution}, {degree.year}</p>
                                    </motion.li>
                                ))}
                            </ul>
                        </motion.div>
                    </ErrorBoundary>
                </div>
                <motion.div
                    className="about-image"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                >
                    <div className="image-container">
                        <img 
                            src={profileImage} 
                            alt="Profile" 
                            onContextMenu={disableContextMenu}
                            draggable="false"
                        />
                        <div className="image-overlay" />
                    </div>
                </motion.div>
            </section>
        </ErrorBoundary>
    );
});

export default Profile;
