import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/App.css';
import '../context/ThemeMode.css';
import useScrollToHash from '../hooks/useScrollToHash';
import useSectionVisibility from '../hooks/useSectionVisibility';

// Layout components
import HeaderInfo from './Header';
import NavBarSide from './NavBarSB';  
import Footer from './Footer';
// Feature components
import Welcome from './Welcome';
import Service from './Service';
import Expertise from './Expertise';
import Insight from './Insight';
import Profile from './Profile';
// UI components
import ThemeMode from '../context/ThemeMode';
import HireMeButton from './Hire';


function App() {
  useScrollToHash();
  useSectionVisibility();

  return (
    <div className="App">
      <Helmet>
        <title>Steven Marquez | Blockchain Developer & Consultant</title>
        <meta name="description" content="Steven Marquez - Blockchain Developer and Consultant. Specializing in smart solutions, blockchain integration, and decentralized applications. Book a free consultation today." />
        <meta name="keywords" content="blockchain developer, blockchain consultant, smart contracts, decentralized applications, DeFi, Web3, cryptocurrency, blockchain solutions" />
        <meta name="author" content="Steven Marquez" />
        <meta property="og:title" content="Steven Marquez - Blockchain Developer & Consultant" />
        <meta property="og:description" content="Driven by the vision of a connected, decentralized future. Specializing in blockchain integration, enhancing security, and pioneering industry advancements." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://stevenmarquez.com" />
        <meta property="og:image" content="%PUBLIC_URL%/IMG_9685.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@stevenmarquez_x" />
      </Helmet>
      <header>
        <HeaderInfo />
        <NavBarSide />
        <ThemeMode />
      </header>
      <main className="main-content">
        <Welcome />
        <HireMeButton />
        <Service />
        <Expertise />
        <Insight />
        <Profile />
      </main>
      <Footer />
    </div>
  );
}

export default App;