// NavbarSide.js
import React, { useState, useEffect, useCallback } from 'react';
import '../styles/NavBarSB.css';

const NavBarSide = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navItems = [
    { label: 'Welcome', id: 'welcome' },
    { label: 'Service', id: 'service' },
    { label: 'Expertise', id: 'expertise' },
    { label: 'Insight', id: 'insight' },
    { label: 'Profile', id: 'profile' },
  ];

  const handleScroll = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -60; // Adjust this value based on your navbar height
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setIsMenuOpen(false); // Close menu after clicking a link
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-20% 0px -80% 0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`custom-navbar-background ${isMenuOpen ? 'menu-open' : ''}`} aria-label="Side navigation" data-active-section={activeSection}>
      {isMobile && (
        <div className="mobile-nav-header">
          <span className="active-section-label">
            {navItems.find(item => item.id === activeSection)?.label || ''}
          </span>
          <button className="hamburger-menu" onClick={toggleMenu} aria-expanded={isMenuOpen}>
            <span className="sr-only"></span>
            <i className={`bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`}></i>
          </button>
        </div>
      )}
      <ul className={`nav-list ${isMobile ? (isMenuOpen ? 'show' : '') : 'show'}`}>
        {navItems.map(({ id, label }) => (
          <li className="nav-item" key={id}>
            <button
              className={`nav-link ${activeSection === id ? 'active' : ''}`}
              onClick={() => handleScroll(id)}
              aria-current={activeSection === id ? 'page' : undefined}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default React.memo(NavBarSide);
