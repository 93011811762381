import React from 'react';
import '../styles/Service.css';
import discoveryImg from '../assets/chat-bubble-dynamic-color.png';
import strategyImg from '../assets/eth-dynamic-color.png';
import developmentImg from '../assets/sheild-dynamic-color.png';
import supportImg from '../assets/rocket-dynamic-color.png';

const services = [
  {
    title: "Discovery & Consultation",
    subtitle: "Our Initial Meeting",
    image: discoveryImg,
    description: "Share your vision in our initial meeting. We'll define your project's goals and technical needs."
  },
  {
    title: "Strategy & Design", 
    subtitle: "Crafting Solution Architecture",
    image: strategyImg,
    description: "I'll design the blueprint of your smart contract solution, ensuring it aligns with your business strategy."
  },
  {
    title: "Development & Testing",
    subtitle: "Bringing Ideas to Life", 
    image: developmentImg,
    description: "Your smart contracts are developed, tested, and refined for peak performance and ironclad security."
  },
  {
    title: "Deployment & Support",
    subtitle: "Launching Your Solution",
    image: supportImg,
    description: "After deploying your solution, I provide full documentation and support to ensure you're equipped for success."
  }
];

const ServiceCard = ({ title, subtitle, image, description }) => {
  const disableContextMenu = React.useCallback((e) => e.preventDefault(), []);

  return (
    <div className="service-card">
      <div className="service-image">
        <img 
          src={image} 
          alt={title} 
          onContextMenu={disableContextMenu}
          draggable="false"
        />
        <div className="image-overlay" />
      </div>
      <div className="service-content">
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Service = () => {
  const handleGetStarted = React.useCallback(() => {
    window.open('https://calendly.com/', '_blank', 'noopener,noreferrer');
  }, []);

  return (
    <section className="service-section" id="service">
      <h2>Concept to Creation</h2>
      <div className="service-container">
        <div className="service-grid">
          {services.map((service) => (
            <ServiceCard key={service.title} {...service} />
          ))}
        </div>
        <div className="button-wrapper">
          <button 
            className="get-started-btn"
            onClick={handleGetStarted}
            aria-label="Get Started"
            style={{ pointerEvents: 'auto' }}
          >
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default Service;