import React, { useState, useEffect, useCallback } from 'react';
import './ThemeMode.css';

const ThemeMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedPreference = localStorage.getItem('isDarkMode');
    if (storedPreference !== null) {
      return JSON.parse(storedPreference);
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    const storedPreference = localStorage.getItem('isDarkMode');
    if (storedPreference === null) {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (e) => setIsDarkMode(e.matches);

      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);

  useEffect(() => {
    const detectDeviceTheme = () => {
      const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(isDarkTheme);
    };

    detectDeviceTheme();

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addListener(detectDeviceTheme);

    return () => mediaQuery.removeListener(detectDeviceTheme);
  }, []);

  const toggleTheme = useCallback(() => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  }, []);

  return (
    <div className="theme-mode-wrapper">
      <label className="switch">
        <input 
          type="checkbox" 
          checked={isDarkMode}
          onChange={toggleTheme}
          aria-label={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
        />
        <span className="slider" />
      </label>
    </div>
  );
};

export default ThemeMode;
