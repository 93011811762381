import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import '../styles/Insight.css';
import image1 from '../assets/X Article Image.1 - 10.11.24.png';
// import image2 from '../assets/blockchain-bg.jpg';            

const InsightCard = React.memo(({ image, title, description, platform, link }) => {
  const disableContextMenu = useCallback((e) => e.preventDefault(), []);

  return (
    <motion.div
      className="insight-card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="insight-image-container">
        <img 
          src={image} 
          alt="" 
          className="insight-image" 
          onContextMenu={disableContextMenu}
          draggable="false"
        />
        <div className="image-overlay" />
      </div>
      <div className="insight-content">
        <h3 className="insight-title">{title}</h3>
        <p className="insight-description">{description}</p>
        <div className="insight-footer">
          <a 
            href={link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="view-button"
            aria-label={`View article: ${title}`}
          >
            EXPLORE
          </a>
        </div>
      </div>
    </motion.div>
  );
});

const Insight = () => {
  const insights = useMemo(() => [
    {
      image: image1,
      title: "Is Tesla Pioneering V2X or the Future of R2X? Let's Dive Into What's Next.",
      description: "Imagine a world where cars communicate with each other, infrastructure, and even robots, enhancing safety and efficiency. Setting the stage for smart cities. Are we ready for a future where cars park themselves and robots assist in daily life? ",
      platform: 'X',
      link: 'https://x.com/stevenmarquez_x/status/1844930695623245944?s=46'
    },
    // {
    //   image: image2,
    //   title: 'Smart Contract Implementation: A Case Study',
    //   description: 'Exploring the challenges and solutions in implementing smart contracts for a major corporation.',
    //   platform: 'X',
    //   link: 'https://x.com/stevenmarquez_x'
    // },
    // Add more articles or case studies as needed
  ], []);

  return (
    <section className="insight-section" id="insight">
      <h2>Latest Insights</h2>
      <div className="insight-grid">
        {insights.map((insight, index) => (
          <InsightCard key={index} {...insight} />
        ))}
      </div>
    </section>
  );
};

export default Insight;