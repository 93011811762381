import React, { useState, useEffect } from 'react';
import '../styles/Hire.css';

const HireMeButton = () => {
  const [buttonText, setButtonText] = useState('Hire Me');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const transitionDuration = 500; // 500ms for smooth transition
    let timeoutId;

    const changeText = () => {
      setButtonText((prevText) => {
        if (prevText === 'Hire Me') {
          return isMobile ? 'Tap Here' : 'Click Here';
        } else {
          return 'Hire Me';
        }
      });
    };

    const interval = setInterval(() => {
      // Start fading out
      document.querySelector('.hire-me-button').style.opacity = '0';
      
      // Change text and fade in after transition
      timeoutId = setTimeout(() => {
        changeText();
        document.querySelector('.hire-me-button').style.opacity = '1';
      }, transitionDuration);

    }, 4000); // Change text every 4 seconds (2s visible + 0.5s fade out + 0.5s fade in)

    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, [isMobile]);

  const handleClick = () => {
    window.open('https://app.usebraintrust.com/auth/login/?next=%2F', '_blank', 'noopener,noreferrer');
  };

  return (
    <button
      onClick={handleClick}
      className="hire-me-button"
      aria-label="Hire Me"
      style={{ transition: 'opacity 0.5s ease-in-out' }}
    >
      <span className="availability-dot" />
      {buttonText}
    </button>
  );
};

export default HireMeButton;