import React, { useState, useEffect } from 'react';
import '../styles/Welcome.css';
import { FaXTwitter, FaLinkedin } from 'react-icons/fa6';
import CryptoGrid from './CryptoGrid';

const Welcome = () => {
  const [greeting, setGreeting] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        setGreeting('Good morning,');
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting('Good afternoon,');
      } else {
        setGreeting('Good evening,');
      }
    };

    updateGreeting();
    const timer = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleConsultationClick = () => {
    window.open('https://calendly.com/', '_blank', 'noopener,noreferrer');
  };

  const handleTwitterClick = () => {
    window.open('https://x.com/stevenmarquez_x', '_blank', 'noopener,noreferrer');
  };

  const handleLinkedInClick = () => {
    window.open('https://linkedin.com/in/steven-marquez-jr-b2155a238', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {isMobile && (
        <section className="crypto-section">
          <CryptoGrid />
        </section>
      )}
      
      <section className="welcome-section" id="welcome">
        <div className="welcome-content">
          <h1 className="responsive-greeting">{greeting}</h1>
          <h2 className="responsive-title">Let's Create Smart Solutions</h2>
          <p className="responsive-text">Driven by the vision of a connected, decentralized future, I am passionate about innovation through blockchain integration, enhancing security, efficiency, and pioneering industry advancements.</p>
          <p className="responsive-text">Schedule a meeting below or contact with me on X to discuss your project and how I can help you achieve your goals.</p>
          <div className="button-container">
            <div className="button-wrapper">
              <button 
                className="consultation-button"
                onClick={handleConsultationClick}
                style={{ pointerEvents: 'auto' }}
              >
                Book a Free Consultation
              </button>
            </div>
            <div className="social-buttons">
              <div className="button-wrapper">
                <button 
                  className="dm-button"
                  onClick={handleTwitterClick}
                  style={{ pointerEvents: 'auto' }}
                >
                  Connect on <FaXTwitter />
                </button>
              </div>
              <div className="button-wrapper">
                <button 
                  className="dm-button"
                  onClick={handleLinkedInClick}
                  style={{ pointerEvents: 'auto' }}
                >
                  Connect on <FaLinkedin />
                </button>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && <CryptoGrid />}
      </section>
    </>
  );
};

export default Welcome;