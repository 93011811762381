import { useEffect } from 'react';

const useScrollToHash = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash !== '#welcome') {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
};

export default useScrollToHash; 