import { useEffect } from 'react';

const useSectionVisibility = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            if (sectionId !== 'welcome') {
              window.history.replaceState(null, '', `#${sectionId}`);
            } else {
              window.history.replaceState(null, '', window.location.pathname);
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const sections = document.querySelectorAll('section[id]');
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
};

export default useSectionVisibility; 